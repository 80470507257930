import React from 'react';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
// import bannerImg from '../../assets/images/bg-main.jpg';
import Action2Part from '../../componets/action2Part/Index';
import GetInTouchPart from '../../componets/getInTouchPart/Index';
import PricingPage from '../../pages/pricing/index';
// import {
//   faArrowDown,
//  } from '@fortawesome/free-solid-svg-icons';
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServicesTitlePart from '../../componets/servicesPart/Index';
import SocialLink from '../../layouts/partials/header/SocialLink';

class OffshoreTechTeams extends React.Component {
    render() {
        return ( 
          <Layout>
            {/* <div className="banner-area-3 bg_cover" style={{backgroundImage: `url(${process.env.PUBLIC_URL}'/'${bannerImg})`}}>
              <div className="banner-overlay"> */}
                {/* <img className="item-1" src="../../assets/images/bg-london-1.jpg" alt="" />
                <Container>
                  <Row className="align-items-center">
                    <Col lg="12">
                      <div className="banner-content text-center">
                        <span>Ukko Tech</span>
                        <Row >
                          <Col lg="12" className="banner-content text-center">
                            <h1 className="service-title" style={{color: '#fff !important'}}>Staff Outsourcing</h1>
                            <FontAwesomeIcon icon={faArrowDown} className='arrow-down'/>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row> 
                </Container>  */}
                
              {/* </div>
            </div>  */}
            <ServicesTitlePart />
            {/* <Container>
                <Row className="justify-content-left">
                  <Col lg="6" className="mt-4">
                    <div className="page-title-item text-center">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link  to={"/"}>Home </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            Your Offshore Tech Team
                          </li>
                        </ol>
                      </nav>
                    </div>
                </Col>
              </Row>
            </Container> */}
            <div className="blog-standard-area">
            <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <div className="blog-standard">
                      <div className="single-blog-standard mt-20 mb-20">
                        <div className="blog-dteails-content blog-border">
                          <div className="blog-details-top">
                            <div className='footer-area'>
                              <div className="header-right-social mt-10 mb-10">
                                <SocialLink/>
                              </div> 
                            </div>
                            
                            {/* <h2 className="title">Building your offshore tech team</h2> */}
                            {/* <p>
                              At Ukko Tech, we specialize in helping you build and manage offshore tech teams. As part of Europe's First e-Park, we utilize significant tax discounts to enhance your cost-efficiency and provide unparalleled services.
                            </p> */}
                            <h4 className="sub-title">Enhanced Access and Control:</h4>
                            <p> Our service at Ukko Tech is centred around staff outsourcing, offering UK companies direct access and control over the hired team. This increased control positively impacts the quality of the final product, as the UK company actively manages and guides the offshore team according to their standards.</p>
                            <h4 className="sub-title">Tailored to Your Standards</h4>
                            <p>With a focus on staff outsourcing, UK companies can shape the work environment, workflows, and standards. This ensures that the end product meets specific requirements and aligns with the high-quality benchmarks expected</p>
                            <h4 className="sub-title">Quality Assurance</h4>
                            
                              <b>Concerns about Outsourcing to popular BPO locations like India:</b>
                              We understand the reservations UK companies may have when considering outsourcing to popular BPO locations such as India, particularly regarding challenges in maintaining the desired quality of the end product.   
                            <ul>
                              <li>
                                <i className="fal fa-check" /> <b>Robust Quality Assurance Processes: Rest assured, Ukko Tech implements robust quality assurance processes to ensure that the work delivered by the offshore team aligns with the high standards expected by UK companies.</b>
                              </li>
                              <li>
                                <i className="fal fa-check" /> <b>Active Client Involvement: Clients have the opportunity to be actively involved in the development process, fostering collaboration. This involvement allows clients to provide feedback and guidance to maintain the desired quality throughout the whole project / or lead the team by their own. </b>
                              </li>
                            </ul>
                          </div>
                          <div className="blog-details-bath mt-40">
                            <h4 className="sub-title">Why should you consider building your tech tem in Moldova?</h4>
                          </div>
                          <div className="choose-area-ofsore-tech-team" >
                            <Row>
                              <Col lg="6">
                                <div className="choose-cat">
                                  <div
                                    className="choose-cat-item mt-20 wow fadeInLeft"
                                    data-wow-duration=".5s"
                                    data-wow-delay=".4s"
                                  >
                                    <h4 className="title">Access to a Wide Range of Developers</h4>
                                      <ul>
                                        <li>
                                          We have access to delveloper at all levels, the majority of them are experienced in doing business with western companies.
                                        </li>
                                      </ul>
                                    <i className="fal fa-check" />
                                  </div>

                                  <div
                                    className="choose-cat-item mt-20 wow fadeInLeft"
                                    data-wow-duration=".5s"
                                    data-wow-delay=".4s"
                                  >
                                    <h4 className="title">Spacious Office</h4>
                                      <ul>
                                        <li>
                                          We're able to host an unlimited amount of employees on your behalf.
                                        </li>
                                      </ul>
                                    <i className="fal fa-check" />
                                  </div>

                                  <div
                                    className="choose-cat-item mt-20 wow fadeInLeft"
                                    data-wow-duration=".5s"
                                    data-wow-delay=".4s"
                                  >
                                    <h4 className="title">Tax Facilities for both the business and the employees.</h4>
                                      <ul>
                                        <li>
                                          The IT sector is growing steadily in Moldova as the government implements incentives to create a favorable business climate.
                                        </li>
                                      </ul>
                                    <i className="fal fa-check" />
                                  </div>

                                  <div
                                    className="choose-cat-item mt-20 wow fadeInLeft"
                                    data-wow-duration=".5s"
                                    data-wow-delay=".4s"
                                  >
                                    <h4 className="title">Lower Cost of Living</h4>
                                      <ul>
                                        <li>
                                          Moldova has a lower cost of living compared to the UK, resulting in lower salaries to meet employees' needs.
                                        </li>
                                      </ul>
                                    <i className="fal fa-check" />
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6">
                                  <div className="team-join-thumb mt-100">
                                    <img src="assets/images/team-join-thumb.png" alt="" />
                                  </div>
                              </Col>
                            </Row>
                          </div>
                          <Row className='mt-20'>
                            <Col lg="12">
                              <div className="blog-details-top">
                                <h2 className="title  mb-4" >Unlocking Cost Savings: A Team Composition Example</h2>
                                <p>
                                  Let's delve into the tangible cost savings that can be achieved by strategically composing your development team. Imagine assembling a team of <strong>7</strong> skilled professionals for your project, including a Lead Developer, Senior Developers, Mid-level Developers, a Graphic Designer, and a QA Engineer.
                                </p>
                                <Row>
                                  <Col lg="6">
                                    <p>
                                      When operating from the UK, the average cost for this team would amount to approximately £<strong>280,000</strong> annually. However, an alternative approach of building a similar tech team in Moldova presents a noteworthy difference. The estimated cost for the Moldovan team totals around £<strong>173,000</strong>, reflecting potential savings of up to <strong>50%</strong>.
                                    </p>
                                    <br/>
                                    <p>
                                      These figures emphasize the significant financial advantages associated with considering offshore options like Moldova. By making such strategic decisions, your organization not only benefits from a diverse pool of talent but also gains a substantial reduction in operational costs.
                                    </p>
                                  </Col>
                                  <Col lg="6">
                                    <div className="choose-thumb-area">
                                        <div className="team-join-thumb">
                                          <img src="assets/images/salary-comparation.png" alt="" />
                                        </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              <PricingPage />
              </Container>
              <Action2Part />
              <GetInTouchPart />
            </div>
          </Layout>
         );
    }
}
 
export default OffshoreTechTeams;