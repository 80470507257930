import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  faArrowDown,
 } from '@fortawesome/free-solid-svg-icons';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ChoosePart extends React.Component {

  
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal = event  => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  render() { 
      return ( <div className="choose-area" id="home_our_services">
        <Container>
          {/* "Why Choose Us" section */}
          <Row>
            <Col lg="12">
              <div className="section-title">
                <span>Who we are</span>
                <h3 className="title">What We Do</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="choose-cat">
                <div
                  className="choose-cat-item mt-40 wow fadeInLeft"
                  data-wow-duration=".5s"
                  data-wow-delay=".4s"
                >
                  <h4 className="title">DevOps</h4>
                  <ul>
                    <li>Continuous Integration and Continuous Deployment (CI/CD);</li>
                    <li>Infrastructure as Code (IaC);</li>
                    <li>Cloud Services and Deployment (AWS, Azure, Google Cloud);</li>
                    <li>Containerization (Docker, Kubernetes);</li>
                  </ul>
                  <i className="fal fa-check" />
                </div>
                <div
                  className="choose-cat-item mt-40 wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".6s"
                >
                  <h4 className="title">Software Development</h4>
                  <ul>
                    <li>SaaS & CRM Development;</li>                    
                    <li>Microservices Architecture;</li>
                    <li>System Design and Architecture;</li>
                    <li>Database Architecture & Maintenance;</li>
                    <li>Integration with Third-party APIs;</li>
                    <li>Mobile App Development;</li>
                    <li>User Experience (UX) Design;</li>
                  </ul>
                  <i className="fal fa-check" />
                </div>
              </div>
            </Col>
            <Col lg="6">
            <div className="choose-cat">
                <div
                  className="choose-cat-item mt-40 wow fadeInLeft"
                  data-wow-duration="4.5s"
                  data-wow-delay="2s"
                >
                  <h4 className="title">Data Science and Analytics</h4>
                  <ul>
                    <li>Data Analysis and Visualization;</li>
                    <li>Machine Learning and AI Solutions;</li>
                    <li>Predictive Analytics;</li>
                    <li>Big Data Processing;</li>
                  </ul>
                  <i className="fal fa-check" />
                </div>
                <div
                  className="choose-cat-item mt-40 wow fadeInLeft"
                  data-wow-duration="5s"
                  data-wow-delay="2.2s"
                >
                  <h4 className="title">Project Management</h4>
                  <ul>
                    <li>Agile Methodologies (Scrum, Kanban);</li>
                    <li>HR & Resource Management;</li>
                    <li>Options for building your dedicated offshore team;</li>
                  </ul>
                  <i className="fal fa-check" />
                </div>
              </div>
            </Col>
            </Row>
            <Row>
              <Col lg="12" className='mt-80'>
                  <div className="banner-content text-center">
                      <h1 className="section-title">Staff Outsourcing</h1>
                      <FontAwesomeIcon icon={faArrowDown} style={{fontSize: 22}}/>
                  </div> 
              </Col>
            </Row>
        </Container>
      </div>
      );
    }
}
 
export default ChoosePart;