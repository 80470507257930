import React from 'react';
import Header from './partials/header/Header4';
import Footer from './partials/footer/index';

class Layout extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Header />
                    { this.props.children }
                <Footer/>
            </React.Fragment>
        )

    }
}

export default Layout