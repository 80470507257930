import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';


class Action2Part extends React.Component {

  render() { 

    const handleDownload = () => {
      const link = document.createElement('a');
      // Adjust the href to point to the correct path
      link.href = process.env.PUBLIC_URL + '/assets/files/UKKO-offshore-tech-teams.pdf';
      link.download = 'UKKO-offshore-tech-teams.pdf';
      link.click();
    };
    
    return ( 
      <React.Fragment>
        {
          /*====== ACTION 2 PART START ======*/
        }
        <div
          className="action-2-area bg_cover"
          style={{ backgroundImage: "url(assets/images/action-bg.jpg)" }}
        >
          <div className="action-overlay">
            <Container>
              <Row className="align-items-center">
                <Col lg="8">
                  <div className="action-item mt-30">
                    <h2 className="title">
                      Download Our Service Brochure
                    </h2>
                  </div>
                  {/* action item */}
                </Col>
                <Col lg="4">
                  <div className="action-support d-flex mt-30">
                  <button
                    className="action-support-item action-support-item-2 wow bounceInUp"
                    data-wow-duration=".7s"
                    data-wow-delay=".7s"
                    onClick={handleDownload}
                  >
                    <FontAwesomeIcon icon={faFileExport} />
                    <p>Download Service Brochure</p>
                  </button>
                  </div>
                  {/* action support */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
        </div>
        {
          /*====== ACTION 2 PART ENDS ======*/
        }
      </React.Fragment>
      );
    }
}
 
export default Action2Part;