import React from 'react';
import bannerImg from '../../assets/images/bg-main.jpg';
import { Container, Row, Col } from 'react-bootstrap';
// import SliderCustom from './SliderCustom'

class Banner extends React.Component {

  render() { 

    return ( 
      <React.Fragment>
        
      <div className="banner-area-3 bg_cover" style={{backgroundImage: `url(${process.env.PUBLIC_URL}'/'${bannerImg})`}}>
      {/* <div className="banner-area-3 bg_cover" style={{backgroundImage: `url(${process.env.PUBLIC_URL}'/'${bannerImg})`}}> */}
        <div className="banner-overlay">
          <img className="item-1" src="../../assets/images/bg-london-1.jpg" alt="" />
          <Container>
            <Row className="align-items-center">
              <Col lg="12">
                <div className="banner-content text-center">
                  <span>Ukko Tech</span>
                  <h1 className="service-title">Building Products &amp; Teams</h1>
                </div> 
              </Col>
            </Row> 
          </Container> 
        </div>
      </div>
    </React.Fragment>
    );
  }
}
 
export default Banner;