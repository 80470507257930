import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NewsPart from '..//NewsPart/Index'
// import {
//   faArrowDown,
//  } from '@fortawesome/free-solid-svg-icons';
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Portfolio3Part extends React.Component {

    render() { 
        return ( 
              <div className="portfolio-3-area pt-60 pb-40">
                <Container>
                  <Row className="justify-content-center">
                    <Col lg="8">
                      <div className="section-title text-center">
                      <span>Parteners</span>
                        <h1 className="sub-title mb-40 " >Our Clients</h1>
                        {/* <FontAwesomeIcon  className="mb-20" icon={faArrowDown} style={{fontSize: 22}}/> */}
                      </div>
                    </Col>
                  </Row>
                </Container>
                <NewsPart/>
              </div>
         );
    }
}
 
export default Portfolio3Part;