import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class ServicesItemPart extends React.Component {

    render() { 
        return ( 
          <div className="services-item-area pb-20">
            <Container>
              <Row className="justify-content-center">
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-1.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-map-marked-alt" />
                      <h4 className="title">Geographical and Cultural Proximity</h4>
                      <p>
                        Moldova's advantage over India lies in its closer geographical and cultural proximity. This proximity fosters better communication, collaboration, and understanding, contributing to a superior end product.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30" >
                    <img src="assets/images/services-thumb-2.jpg" alt=""  style={{minHeight: '248px'}}/>
                    <div className="services-overlay">
                      <i className="fal fa-clock" />
                      <h4 className="title">Time Zone Alignment</h4>
                      <p>
                        Experience seamless real-time communication and coordination with Moldova, which shares a closer time zone with the UK compared to India.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-3.avif" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-book" />
                      <h4 className="title">Development and Education</h4>
                      <p>
                        Moldova, as a more developed country, ensures a higher quality of work. Its advanced educational and technological infrastructure align better with the high standards expected by UK companies.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default ServicesItemPart;