import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

class NewsPart extends React.Component {

    render() { 
        return ( 
          <div className="news-area news-area-2">
            <Container>
              <Row className="justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-9">
                  <div className="single-news mt-30">
                    <img src="assets/images/brand-1.webp" alt="" />
                    <div className="single-news-overlay">
                      <span>A Real Estate Agency, London (UK)</span>
                      <h5 className="title">
                        <Link  to={"https://www.propertysolvers.co.uk/"}>Web development service & DevOps</Link>
                      </h5>
                    </div>
                  </div>
                  {/* single news */}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-9">
                  <div className="single-news mt-30">
                    <img src="assets/images/brand-3.png" alt="" />
                    <div className="single-news-overlay">
                      <span>A digital agency, Basel(Switzerland)</span>
                      <h5 className="title">
                        <Link  to={"/"}>Web development & App development</Link>
                      </h5>
                    </div>
                  </div>
                  {/* single news */}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-9">
                  <div className="single-news mt-30">
                    <img src="assets/images/brand-02.png" alt="" />
                    <div className="single-news-overlay">
                      <span>Private VIP Travel & Concierge Group, London (UK)</span>
                      <h5 className="title">
                        <Link  to={"/"}>Software Development & DevOps</Link>
                      </h5>
                      
                    </div>
                  </div>
                  {/* single news */}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-9">
                  <div className="single-news mt-30">
                    <img src="assets/images/brand-4.jpeg" style={{'max-height':'180px'}} alt="" />
                    <div className="single-news-overlay">
                      <span>Online Shop, Chisinau, (MD)</span>
                      <h5 className="title">
                        <Link  to={"/"}>Web Development</Link>
                      </h5>
                    </div>
                  </div>
                  {/* single news */}
                </div>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default NewsPart;