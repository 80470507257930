import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Index from './pages/home/index';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import ServicesPage from './pages/service';
import OffshoreTechTeams from './pages/service/details';

import NoMatch from './pages/errors/no-match';
import Preloader from "./componets/preloader/Preloader";

class App extends React.Component {

	render() {
		return (
			<BrowserRouter basename={'/'} >
				<Preloader/>
				<Switch>
			
				  <Route exact path="/" component={Index}/>				  

				  <Route path="/about" component={AboutPage}/>
				  <Route path="/services" component={ServicesPage}/>
				  <Route path="/offshore-tech-teams" component={OffshoreTechTeams}/>

				  {/* <Route path="/case-study" component={CaseStudy}/>
				  <Route path="/case-details" component={CaseDetails}/>
				  <Route path="/coming-soon" component={ComingSoon}/> */}
				  <Route path="/error" component={NoMatch}/>
				  {/* <Route path="/faq" component={FaqPage}/>
				  <Route path="/pricing" component={PricingPage}/> */}

				  {/* <Route path="/products" component={ProductPage}/>
				  <Route path="/products-details" component={ProductDetailPage}/>
				  <Route path="/shop" component={ShopPage}/>

				  <Route path="/shop-details" component={ShopDetailsPage}/>
				  <Route path="/team" component={TeamPage}/>
				  <Route path="/team-details" component={TeamDetailsPage}/>
				  <Route path="/blog-standard" component={BlogStandard}/>
				  <Route path="/blog-grid" component={BlogGrid}/>
				  <Route path="/blog-details" component={BlogDetails}/>
				  <Route path="/blog-details2" component={BlogDetails2}/> */}

				  <Route path="/contact" component={ContactPage}/>

				  <Route component={NoMatch} /> 
				 
				</Switch>
			<ToastContainer />
			
		</BrowserRouter>
		 );
	}
}
 
export default App;
