import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ServicesItemPart from '../../componets/servicesItemPart/Index'

class PricingHomePage extends React.Component {

  render() {   
      return ( 
        <>
      <div>
        <div className="services-plans-area pt-40 bg-custom-services-plans">
          <Container>
            <Row className="align-items-center">
              <Col  md="12">
                <div className="services-title mb-20">
                  <span>Choose Moldova for a higher-quality end product.</span>
                  <h2 className="title">Moldova's Superiority</h2>
                </div>
              </Col>
            </Row>
            {/* <Row> */}
              <ServicesItemPart />
            {/* </Row> */}
          </Container>
        </div>
      </div>
      </>
    );
  }
}
 
export default PricingHomePage;