import React from 'react';
import PartialNav from './PartialNav';
import { Link } from "react-router-dom";
import { Navbar } from 'react-bootstrap';
import Logo from '../../../assets/images/ukko-logo.jpg';
import { Link as ScrollLink} from 'react-scroll';

class Header extends React.Component {

    render() { 
        return (
            <>
              <header className="header-area-2 header-area-3">
                
              <div className="header-nav">
                <div className="navigation">
                <Navbar expand="lg" className="transparent-color">
                    <Navbar.Brand as={Link} to={'/'}>
                      <img src={Logo} style={{width: 130}} alt="" />
                    </Navbar.Brand>

                    <Navbar.Toggle
                      aria-controls="navbarSupportedContent"
                      aria-label="Toggle navigation">
                      <span className="toggler-icon" />
                      <span className="toggler-icon" />
                      <span className="toggler-icon" />
                    </Navbar.Toggle> 

                    <Navbar.Collapse className="sub-menu-bar"  id="navbarSupportedContent">
                      <PartialNav/>
                    </Navbar.Collapse> 
                    <div className="navbar-btn mr-100">
                      <ScrollLink className="main-btn" to="home_get_in_touch_area_22" smooth={true} duration={1000}>
                        Get In Touch
                      </ScrollLink>
                    </div>
                  </Navbar>
                </div> 
              </div>
            </header>
          </>
         );
    }
}
 
export default Header;