import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faLinkedinIn,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';


class SocialLink extends Component {
    render() { 
        return ( 
            <ul>
                <li>
                    <a href="https://www.facebook.com/profile.php?id=61554807964387" target="__blank">
                        <FontAwesomeIcon icon={faFacebookF}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/ukko-tech/about" target="__blank">
                        <FontAwesomeIcon icon={faLinkedinIn}/>

                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@UkkoTech" target="__blank">
                        <FontAwesomeIcon icon={faYoutube}/>
                    </a>
                </li>
            </ul>
         );
    }
}
 
export default SocialLink;