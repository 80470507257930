import React from 'react';
import { Link } from 'react-router-dom';
import AboutExperiencePart from '../../componets/aboutExperiencePart/Index';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../layouts';
// import SliderCustom from '../../componets/banner/SliderCustom'

class AboutPage extends React.Component {
  
    render() { 
        return ( 
          <Layout>
            <React.Fragment>
              <div className="banner-area-3 bg_cover">
                <div className="banner-overlay" >
                  <img className="item-1" src="../../assets/images/bg-main.jpg" alt="" />
                  <Container>
                    <Row className="align-items-center">
                      <Col lg="12">
                        <div className="banner-content text-center">
                          <h3 className="page-title">About Us</h3>
                        </div> 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <nav aria-label="breadcrumb" style={{margin: '10px 0'}}>
                             <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                <Link to={"/"}>Home</Link>
                              </li>
                              <li className="breadcrumb-item active" aria-current="page">
                                About Us
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </Col>
                    </Row> 
                  </Container> 
                </div>
                {/* <SliderCustom /> */}
              </div>    
          
            </React.Fragment>
          
            <AboutExperiencePart/>
            </Layout>
         );
    }
}
 
export default AboutPage;