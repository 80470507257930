import React from 'react';
import Footer from '../../layouts/partials/footer/index';
import Header from '../../layouts/partials/header/Header4';
import Banner from '../../componets/banner/Index4';
import ChoosePart from '../../componets/choosePart/Index';
import CounterPart from '../../componets/counterPart/Index';
import GetInTouchPart from '../../componets/getInTouchPart/Index';
import LeadershipPart from '../../componets/LeadershipPart/Index';
import IntroVideoPart from '../../componets/introVideoPart/Index';
import Portfolio3Part from '../../componets/portfolio/Index3';
import PricingHomePage from '../../pages/pricing/Homepage';

class Index extends React.Component {
     
    render() { 
        return ( 
            <React.Fragment>
                <Header/>
                <Banner/>
                <ChoosePart/>         
                <IntroVideoPart/>
                <PricingHomePage/>
                <Portfolio3Part/>
                <LeadershipPart/>
                <CounterPart/>
                <GetInTouchPart/>
                <Footer/>
            </React.Fragment>
         );
    }
}
 
export default Index;