import React from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';

class PartialNav extends React.Component {
    render() { 
        return ( 
            <>
              <Nav className="mr-auto">
                <li className="nav-item">
                  {/* <ScrollLink className="nav-link" to="home_our_services" smooth={true} duration={1000}>
                    Our Coding Services
                  </ScrollLink> */}
                  <Link className="nav-link" to={'/'}>Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={'/offshore-tech-teams'}>Build Your Offshore Tech Team</Link>
                  {/* <ScrollLink className="nav-link" to="home_building_your_team" smooth={true} duration={1000}>
                  
                  </ScrollLink> */}
                </li>
              </Nav>
            </>
         );
    }
}
 
export default PartialNav;