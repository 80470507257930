import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';


//axios.defaults.baseURL = 'hhttp://localhost:3000/api/';
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

ReactGA.initialize('G-HBXGHE4WF7');
ReactGA.pageview(window.location.pathname + window.location.search);

const Head = () => (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-HBXGHE4WF7"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HBXGHE4WF7');
        `}
      </script>
    </Helmet>
  );

ReactDOM.render(
    <>
      <Head />
      <App />
    </>,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
