import React from 'react';
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../../assets/images/ukko-logo.jpg';
import SocialLink from '../header/SocialLink';

class Footer extends React.Component {

  render() { 
      return ( 
        <React.Fragment>
        <footer className="footer-area footer-area-2 footer-area-1 bg_cover" style={{backgroundImage: 'url(assets/images/footer-bg.jpg)'}}>
          <div className="footer-overlay justify-content-center">
            <Container className="position-relative pl-50">
              <Row>
                <Col lg="4" md="4">
                  <div className="widget-item-1 mt-30">
                    <img src={Logo} alt="" style={{maxWidth: '150px'}}/>
                  </div> {/* widget item 1 */}
                </Col>
                <Col lg="4" md="4" >
                  <Row>
                    <div className="widget-item-2 mt-30">
                      <h4 className="title">Working Hours</h4>
                      <ul>
                        <li>Monday - Friday: 9:00 - 18:00</li>
                      </ul>
                    </div>
                  </Row>
                  <Row>
                    <div className="widget-item-2 mt-30">
                      <h4 className="title">Contact Us</h4>
                      <div className="footer-list">
                        <ul>
                          <li><i className="far fa-envelope"></i> contact@ukkotech.net</li>
                        </ul>
                      </div>
                    </div> 
                  </Row>
                  {
                  /* widget item 2 */}
                </Col>
                <Col lg="4" md="4">
                    <iframe width="250" height="250" src="https://shareables.clutch.co/share/badges/2229359/52628?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Software Developers Retail Moldova"></iframe>
                  </Col>
              </Row> {/* row */}
              <Row>
                <Col  md="12">
                  <div className="header-right-social mt-40">
                    <SocialLink/>
                  </div>  
                </Col>
                <Col lg="12">
                  <div className="footer-copyright">
                    <p>Copyright By@ <span>Ukkotech</span> - {(new Date().getFullYear())}</p>
                  </div> {/* footer copyright */}
                </Col>
              </Row> {/* row */}
            </Container> {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a  href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}

      </React.Fragment>
    );
  }
}
 
export default Footer;