import React from 'react';
import ServicesItemPart from '../../componets/servicesItemPart/Index';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
// import SliderCustom from '../../componets/banner/SliderCustom'

class ServicesPage extends React.Component {

    render() { 

        return ( 
          <Layout>
            <React.Fragment>
              <div className="banner-area-3 bg_cover">
                <div className="banner-overlay" >
                  <img className="item-1" src="../../assets/images/bg-main.jpg" alt="" />
                  <Container>
                    <Row className="align-items-center">
                      <Col lg="12">
                        <div className="banner-content text-center">
                          <h3 className="page-title">Services</h3>
                        </div> 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <nav aria-label="breadcrumb" style={{margin: '10px 0'}}>
                             <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                <Link to={"/"}>Home</Link>
                              </li>
                              <li className="breadcrumb-item active" aria-current="page">
                                Services
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </Col>
                    </Row> 
                  </Container> 
                </div>
                {/* <SliderCustom /> */}
              </div>    
            </React.Fragment>
            
            <ServicesItemPart/>
            {/* <ServicesPlansPart/> */}
            </Layout>
         );
    }
}
 
export default ServicesPage;