import React from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';
import emailjs from 'emailjs-com';

class GetInTouchPart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  
  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  isValidName = (name) => {
    return name.trim() !== '';
  };

  isValidMessage = (message) => {
    return message.trim() !== '';
  };


 
  formHandle = (event) => {
    event.preventDefault();

    if (this.state.isSubmitting) {
      return;
    }

    const { email, name, message } = event.target;

    if (!this.isValidEmail(email.value)) {
      toast.error('Invalid input. Please fill in your email!');
      return;
    }

    if (!this.isValidName(name.value)) {
      toast.error('Invalid input. Please fill in your name!');
      return;
    }

    if (!this.isValidName(message.value)) {
      toast.error('Invalid input. Please fill in a message!');
      return;
    }
    
    this.setState({ isSubmitting: true });

    emailjs.sendForm('service_wk6y1ln', 'template_27ia2nw', event.target, 'Y7majcr4RQ323GqRC')
      .then((response) => {
        this.setState({ isSubmitting: false });
        // Scroll to the top of the page after a brief delay (e.g., 2 seconds)
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 2000);        
        toast.success("Email sent successfully!");
      }, (error) => {
        console.error('Error sending the email:', error);
        toast.error("Error sending the email. Please try again later.");
        this.setState({ isSubmitting: false });
      });
  };

    render() { 
        return ( 
          <div className="get-in-touch-area" id="home_get_in_touch_area_22">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="section-title text-left">
                    <span>Get In Touch</span>
                    <h2 className="title">Contact us</h2>
                  </div>
                  {/* section title */}
                  <div className="form-area">
                    <form action="index" onSubmit={this.formHandle}>
                      <div
                        className="input-box pt-45 wow fadeInLeft"
                        data-wow-duration="1s"
                        data-wow-delay=".2s"
                      >
                        <input type="text" name="name" id="name" placeholder="Enter your name" />
                      </div>
                      <div
                        className="input-box mt-20 wow fadeInLeft"
                        data-wow-duration="1.2s"
                        data-wow-delay=".4s"
                      >
                        <input type="email" name="email" id="email" placeholder="Enter your email" />
                      </div>
                      <div
                        className="input-box mt-20 wow fadeInLeft"
                        data-wow-duration="1.4s"
                        data-wow-delay=".6s"
                      >
                        <textarea
                          name="message"
                          id="message"
                          cols={30}
                          rows={10}
                          placeholder="Enter your message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="input-box mt-20">
                        <button
                          className="main-btn wow fadeInUp"
                          data-wow-duration="1.6s"
                          data-wow-delay=".2s"
                          type="submit"
                          disabled={this.state.isSubmitting}
                        >
                          {this.state.isSubmitting ? 'Submitting...' : 'Submit Now'}
                        </button>
                      </div>
                    </form>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="contact-details-area pt-90 pb-120">
                    {/* <Container>
                      <Row> */}
                          <div className="contact-info">
                            <div className="contact-item-1">
                                <div className="contact-info-item text-center">
                                  <i className="fal fa-map" />
                                  <h5 className="title">Moldova</h5>
                                  <p>Miron Costin 18, Chisinau</p>
                                  <p>+373 6124 2163</p>
                                  <p>Whatsapp: +44 7397 029925</p>
                                </div>
                              </div>
                              <div className="contact-item-1">
                                <div className="contact-info-item text-center">
                                  <i className="fal fa-map" />
                                  <h5 className="title">UK</h5>
                                  <p>Liverpool Rd, Luton</p>
                                  <p>+44 7786279942</p>
                                </div>
                              </div>
                          </div>
                      {/* </Row>
                    </Container> */}
                  </div>

                  {/* <div className="get-map d-none d-lg-block">
                  <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1819.2187466889244!2d28.83466671576556!3d47.02451157469841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cf4234b8e5c175%3A0x26c7cd773f8a108f!2sMiron%20Costin%20Nr18%2C%20Chi%C8%99in%C4%83u%2C%20Moldova!5e0!3m2!1sen!2sus!4v1628088753112!5m2!1sen!2sus"
                      style={{ border: 0 }}
                      allowFullScreen
                      width={600}
                      height={450}
                      title="Our Location"
                    />
                  </div> */}
                </Col>
              </Row>
            </Container>
          </div>
         );
    }
}
 
export default GetInTouchPart;