import React from 'react';
//import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
} from '@fortawesome/free-solid-svg-icons';
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from 'react-bootstrap';

class IntroVideoPart extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal (e) {
    e.preventDefault();
    this.setState({isOpen: true})
  }

  render() { 
  
      return ( 
        <div className="intro-video-area bg_cover"
          style={{ backgroundImage: "url(assets/images/bg-london-2.jpg)" }}
          id = "home_building_your_team"
        >
          <div className="intro-overlay">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="intro-video-content mt-30">
                    <span>Building your team</span>
                    <h2 className="title">Why Build Your Team With Us.</h2>
                    <p className="text-2">
                      We're here to assist you in establishing your dedicated IT team.
                      <br />
                      Being part of Europe's First e-Park, we're taking advantage of significant tax discounts, enhancing your cost-efficiency.
                      <br />
                      <br />
                      We have access to a vast pool of Licensed IT Professionals certified by Technical University of Moldova.
                      <br />
                      <br />
                      Operating from Moldova allows us to take advantage of the lower cost of living, enabling us to reduce your IT expenses by up to 20-30%.
                      <br />
                      <br />
                      Additionally, we offer flexible options for staff relocation, allowing seamless collaboration.
                    </p>
                    <Link 
                      className="main-btn wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".1s"
                      to={"/offshore-tech-teams"}
                    >
                      Learn More
                    </Link>
                  </div>
                  {/* intro video content */}
                </Col>
                <Col lg="6">
                  <div className="intro-thumb mt-100" >
                  <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='C7U5FOOi2SA' onClose={() => this.setState({isOpen: false})} />
                    <img src="assets/images/bg-main.jpg" alt="" style={{maxWidth: '500px'}}/>
                    <Link  className="video-popup" to={"http://bit.ly/2VuPnrx"}  onClick={this.openModal}>
                      <FontAwesomeIcon icon={faPlay} size={`3x`} className={`pl-2 pt-3`}/>
                    </Link>
                  </div>
                  {/* intro thumb */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
        </div>
        );
    }
}
 
export default IntroVideoPart;